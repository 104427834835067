<template>
  <div>
    <v-card outlined>
      <v-card-title>
        <div class="title">Editar Relatório Técnico</div>
      </v-card-title>
      <v-card-text>
        <v-row justify="start" align="start" dense>
          <v-col cols="12" class="">
            <v-card outlined class="px-5 py-0 mb-5">
              <v-radio-group v-model="documentType" row mandatory disabled>
                <template v-slot:label>
                  <div>Escolha o tipo de <strong>documento</strong>:</div>
                </template>
                <v-radio label="Relatório Técnico" value="0"></v-radio>
                <v-radio label="PFG" value="1"></v-radio>
                <v-radio label="TCC" value="2"></v-radio>
              </v-radio-group>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-text-field
              clearable
              outlined
              dense
              required
              label="Titulo"
              :rules="rules"
              v-model="technicalReport.title"
              placeholder="título do documento"
              :disabled="progress"
              counter="255"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              clearable
              outlined
              dense
              required
              :rules="rules"
              label="Autor(es)"
              hint="Lista de autores"
              v-model="technicalReport.author"
              placeholder="autores separados por vírgula."
              :disabled="progress"
              counter="255"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              clearable
              outlined
              dense
              :rules="rules"
              label="Institutição"
              v-model="technicalReport.institution"
              placeholder="ex.: Unicamp"
              :disabled="progress"
              counter="255"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              clearable
              dense
              label="Nota"
              v-model="technicalReport.note"
              outlined
              :rules="rules"
              placeholder="Ex.:In English, 12 pages."
              :disabled="progress"
              counter="255"
            ></v-text-field>
          </v-col>
           <v-col cols="12">
            <v-text-field
              clearable
              dense
              label="Língua"
              v-model="technicalReport.language"
              outlined
              :rules="rules"
              placeholder="english, brazil"
              :disabled="progress"
              counter="50"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="4" class="text-left">
            <v-text-field
              clearable
              dense
              label="Código"
              v-model="technicalReport.code"
              outlined
              placeholder="código da publicação"
              :disabled="progress"
              counter="10"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="4" class="text-left">
            <v-file-input
              accept="application/pdf"
              label="relatório técnico"
              hint="Somente arquivo .pdf"
              persistent-hint
              block
              outlined
              dense
              v-model="reportFile"
              append-icon="attachment"
              :prepend-icon="null"
              :disabled="progress"
            ></v-file-input>
          </v-col>
          <v-col cols="12">
            <v-textarea
              outlined
              dense
              v-model="technicalReport.abstract_"
              label="Abstract"
              :rules="rules"
              placeholder="..."
              counter="2000"
              :disabled="progress"
            >
            </v-textarea>
          </v-col>
          <v-col cols="12" class="">
            <VueMathjax></VueMathjax>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pb-5">
        <v-row justify="center" align="center" dense>
          <v-col cols="12" class="text-center" v-show="progress">
            <v-progress-linear indeterminate color="green"></v-progress-linear>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="text-center">
            <v-btn color="red" outlined @click="close()" :disabled="progress"
              >Cancelar<v-icon>clear</v-icon></v-btn
            >
          </v-col>
          <v-col cols="12" sm="12" md="6" class="text-center">
            <v-btn
              color="green"
              dense
              outlined
              @click="putAsyncTechnicalReport()"
              :disabled="progress"
              >Submeter <v-icon>check</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar" :timeout="timeout" :color="color">
      <v-row justify="center" align="center" class="pa-3">
        {{ snackbarMessage }}
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import { VueMathjax } from "vue-mathjax";

export default {
  name: "TechnicalReportUpdate",
  props: { id: { type: Number, required: false, default: 0 } },
  components: { VueMathjax },

  data() {
    return {
      color: null,
      snackbar: false,
      timeout: 5000,
      snackbarMessage: "",
      form: null,
      technicalReport: {},
      documentType: 0,
      progress: false,
      reportFile: null,
      bibFile: null,
      rules: [(t) => !!t || "O campo não pode ser vazio!!!"],
    };
  },

  created() {
    if (this.id == 0) {
      const id_ = this.$route.params.id;
      this.getAsyncTechnicalReportDetails(id_);
    } else {
      this.getAsyncTechnicalReportDetails(this.id);
    }
  },

  computed: {
    ...mapState("technicalReports", ["technicalReportDetails"]),
  },

  methods: {
    ...mapActions("technicalReports", [
      "ActionPutTechnicalReport",
      "ActionGetTechnicalReportDetails",
    ]),

    async getAsyncTechnicalReportDetails(id) {
      try {
        await this.ActionGetTechnicalReportDetails(id);
      } catch (err) {
        console.log(err);
      }
    },

    async putAsyncTechnicalReport() {
      if (this.validatedFields()) {
        this.progress = true;

        this.form = new FormData();

        this.form.append("id", this.technicalReportDetails.id);
        this.form.append("title", this.technicalReport.title);
        this.form.append("institution", this.technicalReport.institution);
        this.form.append("note", this.technicalReport.note);
        this.form.append("abstract_", this.technicalReport.abstract_);
        this.form.append("author", this.technicalReport.author);
        this.form.append("code", this.technicalReport.code);
        this.form.append("language", this.technicalReport.language);

      
        if (this.reportFile != null)
          this.form.append("reportFile", this.reportFile);

        try {
          await this.ActionPutTechnicalReport(this.form);
          this.color = null;
          this.snackbarMessage = "Relatório atualizado com sucesso!";
          this.close();
        } catch (e) {
          this.color = "red";
          this.snackbarMessage = e.bodyText;
          console.error(e);
        } finally {
          this.snackbar = true;
          this.progress = false;
        }
      } else {
        this.snackbarMessage = "Existem campos vazios!!!!";
        this.color = "red";
        this.snackbar = true;
      }
    },

    validatedFields() {
      if (
        this.technicalReport.title != null &&
        this.technicalReport.author != null &&
        this.technicalReport.abstract_ != null &&
        this.technicalReport.institution != null &&
        this.technicalReport.note != null
      )
        return true;
      return false;
    },

    close() {
       this.$router.go(-1);
    },
  },

  watch: {
    technicalReportDetails(newValue) {
      this.technicalReport = newValue;
    },
  },
};
</script>

<style>
</style>