var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c("v-card-title", [
            _c("div", { staticClass: "title" }, [
              _vm._v("Editar Relatório Técnico")
            ])
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { attrs: { justify: "start", align: "start", dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "px-5 py-0 mb-5",
                          attrs: { outlined: "" }
                        },
                        [
                          _c(
                            "v-radio-group",
                            {
                              attrs: { row: "", mandatory: "", disabled: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "label",
                                  fn: function() {
                                    return [
                                      _c("div", [
                                        _vm._v("Escolha o tipo de "),
                                        _c("strong", [_vm._v("documento")]),
                                        _vm._v(":")
                                      ])
                                    ]
                                  },
                                  proxy: true
                                }
                              ]),
                              model: {
                                value: _vm.documentType,
                                callback: function($$v) {
                                  _vm.documentType = $$v
                                },
                                expression: "documentType"
                              }
                            },
                            [
                              _c("v-radio", {
                                attrs: {
                                  label: "Relatório Técnico",
                                  value: "0"
                                }
                              }),
                              _c("v-radio", {
                                attrs: { label: "PFG", value: "1" }
                              }),
                              _c("v-radio", {
                                attrs: { label: "TCC", value: "2" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          clearable: "",
                          outlined: "",
                          dense: "",
                          required: "",
                          label: "Titulo",
                          rules: _vm.rules,
                          placeholder: "título do documento",
                          disabled: _vm.progress,
                          counter: "255"
                        },
                        model: {
                          value: _vm.technicalReport.title,
                          callback: function($$v) {
                            _vm.$set(_vm.technicalReport, "title", $$v)
                          },
                          expression: "technicalReport.title"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          clearable: "",
                          outlined: "",
                          dense: "",
                          required: "",
                          rules: _vm.rules,
                          label: "Autor(es)",
                          hint: "Lista de autores",
                          placeholder: "autores separados por vírgula.",
                          disabled: _vm.progress,
                          counter: "255"
                        },
                        model: {
                          value: _vm.technicalReport.author,
                          callback: function($$v) {
                            _vm.$set(_vm.technicalReport, "author", $$v)
                          },
                          expression: "technicalReport.author"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          clearable: "",
                          outlined: "",
                          dense: "",
                          rules: _vm.rules,
                          label: "Institutição",
                          placeholder: "ex.: Unicamp",
                          disabled: _vm.progress,
                          counter: "255"
                        },
                        model: {
                          value: _vm.technicalReport.institution,
                          callback: function($$v) {
                            _vm.$set(_vm.technicalReport, "institution", $$v)
                          },
                          expression: "technicalReport.institution"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          clearable: "",
                          dense: "",
                          label: "Nota",
                          outlined: "",
                          rules: _vm.rules,
                          placeholder: "Ex.:In English, 12 pages.",
                          disabled: _vm.progress,
                          counter: "255"
                        },
                        model: {
                          value: _vm.technicalReport.note,
                          callback: function($$v) {
                            _vm.$set(_vm.technicalReport, "note", $$v)
                          },
                          expression: "technicalReport.note"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          clearable: "",
                          dense: "",
                          label: "Língua",
                          outlined: "",
                          rules: _vm.rules,
                          placeholder: "english, brazil",
                          disabled: _vm.progress,
                          counter: "50"
                        },
                        model: {
                          value: _vm.technicalReport.language,
                          callback: function($$v) {
                            _vm.$set(_vm.technicalReport, "language", $$v)
                          },
                          expression: "technicalReport.language"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-left",
                      attrs: { cols: "12", sm: "12", md: "4" }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          clearable: "",
                          dense: "",
                          label: "Código",
                          outlined: "",
                          placeholder: "código da publicação",
                          disabled: _vm.progress,
                          counter: "10"
                        },
                        model: {
                          value: _vm.technicalReport.code,
                          callback: function($$v) {
                            _vm.$set(_vm.technicalReport, "code", $$v)
                          },
                          expression: "technicalReport.code"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-left",
                      attrs: { cols: "12", sm: "12", md: "4" }
                    },
                    [
                      _c("v-file-input", {
                        attrs: {
                          accept: "application/pdf",
                          label: "relatório técnico",
                          hint: "Somente arquivo .pdf",
                          "persistent-hint": "",
                          block: "",
                          outlined: "",
                          dense: "",
                          "append-icon": "attachment",
                          "prepend-icon": null,
                          disabled: _vm.progress
                        },
                        model: {
                          value: _vm.reportFile,
                          callback: function($$v) {
                            _vm.reportFile = $$v
                          },
                          expression: "reportFile"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-textarea", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: "Abstract",
                          rules: _vm.rules,
                          placeholder: "...",
                          counter: "2000",
                          disabled: _vm.progress
                        },
                        model: {
                          value: _vm.technicalReport.abstract_,
                          callback: function($$v) {
                            _vm.$set(_vm.technicalReport, "abstract_", $$v)
                          },
                          expression: "technicalReport.abstract_"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "12" } }, [_c("VueMathjax")], 1)
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "pb-5" },
            [
              _c(
                "v-row",
                { attrs: { justify: "center", align: "center", dense: "" } },
                [
                  _c(
                    "v-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.progress,
                          expression: "progress"
                        }
                      ],
                      staticClass: "text-center",
                      attrs: { cols: "12" }
                    },
                    [
                      _c("v-progress-linear", {
                        attrs: { indeterminate: "", color: "green" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      attrs: { cols: "12", sm: "12", md: "6" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "red",
                            outlined: "",
                            disabled: _vm.progress
                          },
                          on: {
                            click: function($event) {
                              return _vm.close()
                            }
                          }
                        },
                        [_vm._v("Cancelar"), _c("v-icon", [_vm._v("clear")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      attrs: { cols: "12", sm: "12", md: "6" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "green",
                            dense: "",
                            outlined: "",
                            disabled: _vm.progress
                          },
                          on: {
                            click: function($event) {
                              return _vm.putAsyncTechnicalReport()
                            }
                          }
                        },
                        [_vm._v("Submeter "), _c("v-icon", [_vm._v("check")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.timeout, color: _vm.color },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _c(
            "v-row",
            {
              staticClass: "pa-3",
              attrs: { justify: "center", align: "center" }
            },
            [_vm._v(" " + _vm._s(_vm.snackbarMessage) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }